
@font-face {
  font-family: "Inconsolata Condensed";
  src: url('/public/Fonts/Inconsolata_Expanded-Regular.ttf')format('truetype');
  font-weight: normal; /* You can adjust this if necessary */
  font-style: normal;
}

body {
  margin: 0;
  /* font-family: 'Inconsolata Condensed', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(252 231 243);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;


.swiper-container {
  width: 100%;
}

.swiper-width {
  width: 100% !important;
}

.swiper {
  max-width: auto;
  padding: 20px !important;
}

.swiper-slide {
  position: relative;
}

.swiper-carousel {
  position: relative;
  margin: 0 auto;
  max-width: 100%;
  overflow: hidden;
}

.main-section .swiper-slide-active {
  width: 100% !important;
}


.swiper-carousel {
  position: relative;
  margin: 0 auto;
  max-width: 100%;
  overflow: hidden;
}

html {
  scroll-behavior: smooth;
}
